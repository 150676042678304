import * as React from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


export default function Privacy() {
  return (
    <>
    <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container maxWidth="lg">
            <Typography
              component="h1"
              variant="h2"
              color="text.primary"
              gutterBottom
            >
              Aviso de Privacidad
            </Typography>
            <Typography variant="h5"color="text.secondary" paragraph>
Con lo dispuesto en la Ley Federal de Protección de Datos Personales en Posesión de Particulares (en lo sucesivo la “Ley”), su Reglamento y demás disposiciones aplicables en México.
Le hacemos de su conocimiento que la información aquí solicitada, será utilizada únicamente con los siguientes fines:
Brindar información a toda aquella persona interesada en la adquisición de nuestros productos y servicios.
Análisis estadístico y registro histórico de navegación en la página web TodoFix.
Análisis de datos mediante el uso de tecnologías de analítica con el objeto de evaluar el uso los servicios e información proporcionada en la pagina web.
Atención a través de medios electrónicos o de redes sociales.
Encuestas de opinión y satisfacción con la atención brindada en nuestro servicio, para una mejora integral que vayae ncaminada al cumplimiento de nuestros valores comerciales.
La información personal que es recabada a través de nuestro portal, son datos de contacto, datos de identificación, navegación, geolocalización y de dispositivos.
En el caso de acordar la transacción comercial entre el titular interesado y nosotros como prestadores de servicios, podremos solicitarle información que vaya acorde a los intereses del titular, como la información fiscal pertinente para la emisión de facturas, datos de identificación detallados cuando este de por medio un contrato de servicios o cuestiones logísticas relacionadas al envió o entrega de productos en las instalaciones solicitadas por nuestros clientes.
¿Cómo es utilizada tu información?
Toda la información proporcionada en la pagina web de TodoFix, es utilizada para tener un mayor entendimiento de las necesidades expresadas por nuestros clientes  y poder ofrecer un amplia  gama de soluciones que vayan acorde a sus presupuestos y plazos de ejecución requeridos.
Así mismo, proporcionar un adecuado dimensionamiento en los proyectos a desarrollar y un seguimiento eficiente por el personal de manera presencial telefónica y vía electrónica.
Para nosotros, tu opinión es muy importante, por ende, ocasionalmente te contactaremos para realizar encuestas de calidad, estudios de mercado y hacer de tu conocimiento las ofertas y promociones que tengamos en las diferentes temporadas del año.
¿Con quien se comparte tu información?
TodoFix no compartirá o transferirá a terceros que no sean las marcas y/o fabricantes cuyos productos adquieres, los datos personales proporcionados, sin antes hacer de tu conocimiento dicha solicitud.
Puedes ejercer y/o administrar tus derechos de acceso, rectificación, cancelación y oposición, revocación de consentimiento o limitación de uso respecto al tratamiento de tus datos personales a través del correo: todofixpuebla@gmail.com
Cualquier cambio o actualización al presente aviso de privacidad, se notificara a los titulares por todos los medios de comunicación disponibles.

            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >

            </Stack>
          </Container>
          
        </Box>
      </main>
      </>
  );
}