import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import { AppBar, Container, CssBaseline, Divider, Drawer, GlobalStyles, Link, Slide, Stack, TextField, Toolbar, Typography, useScrollTrigger } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';

const fabStyle = {
  position: 'fixed',
  bottom: 16,
  right: 16,
  zIndex:5,
};

function Sitemark() {
  return (
    <Box
    component="img"
    sx={{
      flexGrow:1,
      height: 48,
      width: 48,
      maxHeight: { xs: 48, md: 48 },
      maxWidth: { xs: 48, md: 48 },
    }}
    alt="The house from the offer."
    src="https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/todofix_logo.jpeg?alt=media&token=adbb9b1f-af89-4b23-9495-0742eb07745f"
  />
  );
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  borderColor: theme.palette.divider,
  padding: '8px 12px',
}));


const TikTokIcon = ({ color = "#fff" }) => {
  return (
    <svg
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="28px"
      height="28px"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

function Copyright(props) {
  return (
    <Typography variant="body2" color="white" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="www.todofix.com.mx">
        www.todofix.com.mx
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function Main({props}) {

 const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };


  return (
    <>
        <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
        <CssBaseline />
        {/* Header */}
        <HideOnScroll {...props}>
        <AppBar variant='soft-shadow'>
          <Container maxWidth="lg">
          <StyledToolbar variant="dense" disableGutters>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0}}>
            <Sitemark />
          </Box>
          <Box
            sx={{
              display: { xs: 'none', md: 'flex' },
              gap: 1,
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Button href="/" variant='header' sx={{ my: 1, mx: 1.5 }}>
              INICIO
            </Button>
            <Button href="/us" variant="header" sx={{ my: 1, mx: 1.5 }}>
              NOSOTROS
            </Button>
            <Button href="/services" variant="header" sx={{ my: 1, mx: 1.5 }}>
              SERVICIOS
            </Button>
            <Button href="/projects" variant="header" sx={{ my: 1, mx: 1.5 }}>
              PROYECTOS
            </Button>
            <Button href="/contactus" variant="header" sx={{ my: 1, mx: 1.5 }}>
              CONTACTO
            </Button>
            </Box>
          </Box>
          <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
            <IconButton sx={{ color: "white" }}  onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
              <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <IconButton onClick={toggleDrawer(false)}>
                    <CloseRoundedIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ my: 3 }} />
            
              
                <MenuItem component="a" href="/">INICIO</MenuItem>

              
                <MenuItem  component="a" href="/us" >NOSOTROS</MenuItem>
                <MenuItem  component="a" href="/services" > SERVICIOS</MenuItem>
                <MenuItem component="a" href="/projects" >PROYECTOS</MenuItem>
                <MenuItem component="a" href="/contactus" >CONTACTO</MenuItem>
              </Box>
            </Drawer>
          </Box>
          </StyledToolbar>
          </Container>
        </AppBar>
        </HideOnScroll>

        {props}
        {/* Footer */}
        <Container
          maxWidth={ "xxl"} 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            py: { xs: 8, sm: 10 },
            pl: { xs: 8, sm: 10 },
            pr: { xs: 8, sm: 10 },
            backgroundColor:"#1E3F62",
            textAlign: { sm: 'center', md: 'left' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                minWidth: { xs: '100%', sm: '60%' },
              }}
            >
              <Box sx={{ width: { xs: '100%', sm: '60%' } }}>
                <Box >
                  <img
                    src={
                      'https://firebasestorage.googleapis.com/v0/b/todofix-8d29c.appspot.com/o/grupo_silum.jpeg?alt=media&token=192f359c-a46f-4453-826a-e20c7f8e9a83'
                    }
                    style={{
                      width: '256px',
                      height: 'auto',
                    }}
                    alt="logo of sitemark"
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', sm: 'flex' },
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography color="#FFF" variant="body2" fontWeight={600}>
                Product
              </Typography>
              <Link color="#FFF" href="#">
                Services
              </Link>
              <Link color="#FFF" href="#">
                Team
              </Link>
              <Link color="#FFF" href="#">
                FAQs
              </Link>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', sm: 'flex' },
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography  color="#FFF" variant="body2" fontWeight={600}>
                Company
              </Typography>
              <Link color="#FFF" href="/us">
                About us
              </Link>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', sm: 'flex' },
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Typography color="#FFF" variant="body2" fontWeight={600}>
                Legal
              </Typography>
              <Link color="#FFF" href="/privacy">
                Terms
              </Link>
              <Link color="#FFF" href="/privacy">
                Privacy
              </Link>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
          
              pt:{ xs: 4, sm: 8 },
              width: '100%',
              borderTop: '1px solid',
            }}
          >
            <div>
              <Copyright />
            </div>
            <Stack
              direction="row"
              justifyContent="left"
              spacing={1}
              useFlexGap
              sx={{
                color: 'text.secondary',
              }}
            >
              <IconButton
                color="inherit"
                href="https://www.tiktok.com/@todofixpue?_t=8obOyu1NjCI&_r=1"
                aria-label="GitHub"
                sx={{ alignSelf: 'center', color:"white"}}
              >
                <TikTokIcon />
              </IconButton>
              <IconButton
                color="white"
                href="https://www.instagram.com/todofixpue?igsh=ZTBzaTdidjBpZGtr"
                aria-label="LinkedIn"
                sx={{ alignSelf: 'center', color:"white"}}
              >
                <InstagramIcon />

              </IconButton>
              <IconButton
                color="inherit"
                href="https://www.facebook.com/profile.php?id=61555528052374&mibextid=ZbWKwL"
                aria-label="Facebook"
                sx={{ alignSelf: 'center', color:"white"}}
              >
                <FacebookIcon />

              </IconButton>

              
            </Stack>
          </Box>
        </Container>
        {/* End footer */}
        <a style={fabStyle} aria-label="Chat on WhatsApp" href="https://wa.me/12218410015"><img alt="Chat on WhatsApp" src="https://firebasestorage.googleapis.com/v0/b/csemoguel.appspot.com/o/WhatsAppButtonGreenSmall.svg?alt=media&token=c3f4f4cc-b802-4756-bce7-98ca88c311ac" /></a>

    </>
  );
}